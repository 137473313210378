import React, { useState } from 'react';
import { Link } from "gatsby"
import { FormattedMessage } from "react-intl"

import '../../style/components/homeProducts.scoped.scss'

import Icon from '../../components/iconfontCpt'

import { ProductTypes, FeatureData, MigrateFeatureData } from '../../utils/json/home'

const HomeProducts = ({ pageLang }) => {
  const pagePrefix = pageLang === 'en' ? '/en' : ''

  // product tabs
  const [typeActive, setTypeActive] = useState('mogdb')

  // product animation
  let timer = {}
  function inProductCard(e, cardId, { height, times = 40 }) {
    e.stopPropagation()
    timer[cardId] = null

    // 动画1
    const animationNodes = document.querySelectorAll(`#${cardId} .card-inbetweening`)
    if (!animationNodes || animationNodes.length === 0) return

    let j = 0
    timer[cardId] = null
    timer[cardId] = setInterval(() => {
      animationNodes.forEach((node, i) => {
        const h = height[i]
        if (h) {
          node.style.backgroundPosition = `0 ${-(h * j)}px`
        }
      })
      j++
      if (j >= times) {
        clearPicInterval(cardId)
      }
    }, 50)
  }
  function clearPicInterval(cardId) {
    clearInterval(timer[cardId])
    timer[cardId] = null

    // 重置位置
    const animationNodes = document.querySelectorAll(`#${cardId} .card-inbetweening`)
    if (!animationNodes || animationNodes.length === 0) return
    animationNodes.forEach(node => {
      node.style.backgroundPosition = `0 0`
    })
  }

  return (
    <div className={`product-box ${pageLang}`}>
      <div className="product-types">
        {ProductTypes.map(p =>
          <div className={`type-item ${typeActive === p.key ? 'is-active' : ''}`} key={p.key} onClick={() => setTypeActive(p.key)}>
            {p.label && <Icon type={`icon-${p.key}`} size={24} />}<span>{p.label || p[pageLang] || p.key}</span>
          </div>
        )}
      </div>
      {FeatureData && Object.keys(FeatureData).map(key =>
        <div key={key} className={`product-introduce-box box-${key} ${typeActive === key ? 'show-box' : ''}`}>

          {FeatureData[key] && FeatureData[key].map((item, i) =>
            <div className={`card card${i + 1} ${key === 'tools' ? 'sub-main-card' : (i === 0 ? 'main-card' : '')}`} id={`product_card_${key}_${i + 1}`} key={i} onMouseEnter={e => inProductCard(e, `product_card_${key}_${i + 1}`, item)} onMouseLeave={() => clearPicInterval(`product_card_${key}_${i + 1}`)}>
              {/* 动画图片 */}
              <div className="card-inbetweening card-inbetweening1"></div>
              {(key === 'uqbar' && i < 4) && <div className="card-inbetweening card-inbetweening2"></div>}

              {item.key &&
                <>
                  <div className={`product-icon product-icon-${item.key} normal-status`}></div>
                  <div className={`product-icon product-icon-${item.key} active-status`}></div>
                </>
              }

              <h3 className="card-title"><span className="main">{key === 'tools' ? item.title : <FormattedMessage id={item.title} />}</span></h3>
              <p className="card-desc"><FormattedMessage id={item.content} /></p>
              {item.key &&
                <Link className="btn-more" to={`${pagePrefix}/${item.key}/`}>
                  <span><FormattedMessage id="aboutUs.learnMore" /></span>
                  <Icon type="icon-more" size={18} />
                </Link>
              }
            </div>
          )}
        </div>
      )}

      {/* mdb特殊处理 */}
      <div className={`product-introduce-box box-mdb ${typeActive === 'mdb' ? 'show-box' : ''}`}>
        {MigrateFeatureData && MigrateFeatureData.map((t, tIdx) =>
          <div key={tIdx} className={tIdx === 0 ? 'left-box' : 'right-box'}>
            {t.map((item, i) =>
              <div key={i} className={`card card${i + 1} ${tIdx === 0 ? 'main-card' : ''}`} id={`product_card_mdb_${tIdx}_${i + 1}`} onMouseEnter={e => inProductCard(e, `product_card_mdb_${tIdx}_${i + 1}`, item)} onMouseLeave={() => clearPicInterval(`product_card_mdb_${tIdx}_${i + 1}`)}>
                {/* 动画图片 & icon */}
                {(tIdx === 0 && i === 0) &&
                  <>
                    <div className="card-inbetweening card-inbetweening1"></div>
                    <div className={`product-icon product-icon-mdb normal-status`}></div>
                    <div className={`product-icon product-icon-mdb active-status`}></div>
                  </>
                }
                <h3 className="card-title"><span className="main"><FormattedMessage id={item.title} /></span></h3>
                <p className="card-desc"><FormattedMessage id={item.content} /></p>
                {item.url &&
                  <Link className="btn-more" to={`${pagePrefix}/${item.url}/`}>
                    <span><FormattedMessage id={i === 0 ? 'aboutUs.learnMore' : 'aboutUs.contactUs'} /></span>
                    <Icon type="icon-more" size={18} />
                  </Link>
                }
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
export default HomeProducts