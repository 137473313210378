// banner 文案
const BannerLabel = {
  en: ['MogDB Database 5.0.0 released', 'Graphic Tool - Mogeaver', 'MogDB On Kubernetes - MogDB Stack'],
  zh: ['数据库 MogDB 5.0.0 已全新发布', '图形化工具 - Mogeaver', 'MogDB On Kubernetes - MogDB Stack']
}

// 产品分类
const ProductTypes = [
  { key: 'mogdb', label: 'MogDB' },
  { key: 'uqbar', label: 'Uqbar' },
  { key: 'mdb', zh: '异构数据库迁移', en: 'Heterogeneous Database Migration' },
  { key: 'tools', zh: '管理工具', en: 'Management Tools' }
]
// 产品特性介绍
const MogdbFeatureData = [
  {
    title: 'aboutUs.title',
    content: 'aboutUs.introduce',
    key: 'mogdb',
    height: [194],
    times: 39
  },
  {
    title: 'aboutUs.standardSql',
    content: 'aboutUs.standardSqlDes',
    height: [194],
    times: 35
  }, {
    title: 'aboutUs.transactionSupport',
    content: 'aboutUs.transactionSupportDes',
    height: [182]
  }, {
    title: 'aboutUs.functionsAndStored',
    content: 'aboutUs.functionsAndStoredDes',
    height: [126]
  }, {
    title: 'aboutUs.interfaceCompatibility',
    content: 'aboutUs.interfaceCompatibilityDes',
    height: [163]
  }, {
    title: 'aboutUs.sqlHints',
    content: 'aboutUs.sqlHintsDes',
    height: [142]
  }
]
const UqbarFeatureData = [
  {
    title: "aboutUs.uqbarTitle",
    content: 'aboutUs.uqbarIntroduce',
    key: 'uqbar',
    height: [194],
    times: 33
  }, {
    title: "uqbar.valueTitle1",
    content: 'uqbar.valueDesc1',
    height: [148, 210],
    times: 33
  }, {
    title: "uqbar.valueTitle2",
    content: 'uqbar.valueDesc2',
    height: [196, 86],
    times: 42
  }, {
    title: "uqbar.valueTitle3",
    content: 'uqbar.valueDesc3',
    height: [100, 112],
    times: 42
  }, {
    title: "uqbar.valueTitle4",
    content: 'uqbar.valueDesc4',
    height: [96]
  }
]
const ToolsFeatureData = [
  {
    title: "zCloud for MogDB",
    content: 'zcloud.pageContent',
    key: 'zcloudForMogdb',
    height: [100],
    times: 42
  },
  {
    title: "PTK",
    content: 'ptk.pageContent',
    key: 'ptk',
    height: [90]
  },
  {
    title: "MogHA",
    content: 'aboutUs.mogha',
    key: 'mogha',
    height: [74]
  },
  {
    title: "Mogeaver",
    content: 'aboutUs.mogeaver',
    key: 'mogeaver',
    height: [112],
    times: 42
  },
  {
    title: "MogDB Stack",
    content: 'mogdbstack.pageContent',
    key: 'mogdbStack',
    height: [102]
  }
]


const MigrateFeatureData = [[
  {
    title: "aboutUs.mdbTitle",
    content: 'aboutUs.mdbDesc',
    url: 'mdb',
    height: [172]
  },
  {
    title: "aboutUs.mdbSubTitle",
    content: 'aboutUs.mdbSubDesc',
    url: 'contact',
  }
], [
  {
    title: "aboutUs.fullMigration",
    content: 'aboutUs.fullMigrationDesc',
  },
  {
    title: "aboutUs.increamentalMigration",
    content: 'aboutUs.increamentalMigrationDesc',
  },
  {
    title: "aboutUs.compatibilityAnalysis",
    content: 'aboutUs.compatibilityAnalysisDesc',
  },
  {
    title: "aboutUs.dbComparison",
    content: 'aboutUs.dbComparisonDesc',
  }]
]
const FeatureData = {
  mogdb: MogdbFeatureData,
  uqbar: UqbarFeatureData,
  tools: ToolsFeatureData
}

module.exports = {
  BannerLabel,
  ProductTypes,
  FeatureData,
  MigrateFeatureData
}
