import React, { useState, useEffect, useRef } from 'react';
import { FormattedMessage } from "react-intl"
import { Input } from 'antd'
import { Link, navigate } from "gatsby"
import { Menu, Dropdown } from 'antd'
import Typed from 'typed.js'
import intl from '../intl'

import '../style/index.scoped.scss'

import Icon from '../components/iconfontCpt'
import Seo from '../components/seo'
import Layout from "../components/layout"
import HomeProducts from "../components/home/products"
import HomeCustomers from "../components/home/customers"

import HomePartners from "../components/partners/partners"

import { BannerLabel } from '../utils/json/home'

const { Search } = Input;

const Index = ({ pageContext }) => {
  const pageLang = pageContext.lang
  const pagePrefix = pageLang === 'en' ? '/en' : ''

  // banner 动效
  const bannerContentEl = useRef(null)
  const typed = useRef(null)
  useEffect(() => {
    const options = {
      strings: BannerLabel[pageLang],
      typeSpeed: 100,
      backSpeed: 100,
      loop: true
    }
    typed.current = new Typed(bannerContentEl.current, options)
    return () => {
      typed.current.destroy()
    }
  }, [])

  // 合作伙伴
  const onSearch = value => {
    navigate(pagePrefix + '/partners', { state: { search: value } })
  }
  const [tabActive, setTabActive] = useState('Hardware')
  const menu = (
    <Menu className="p-search-more">
      <Menu.Item>
        <Link to={`${pagePrefix}/partners/`}><FormattedMessage id="index.readMore" /></Link>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item>
        <Link to={`${pagePrefix}/apply/`}><FormattedMessage id="index.apply" /></Link>
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout pageLang={pageLang} showHeader={true} showFooter={true} useTransparentTheme={true} customClass="home-header">
      <Seo title="MogDB: AboutUs" />
      <div className="index-container">
        <div className="video-box"><video src="https://cdn-mogdb.enmotech.com/website/2.0_banner.mov" autoPlay loop muted playsInline className="video" /></div>
        <div className="z-box">
          <div className={`aboutUs-banner`}>
            <p className="title"><FormattedMessage id="index.animationBannerTitle" /></p>
            <p className="product"><span ref={bannerContentEl}></span></p>
          </div>

          <HomeProducts pageLang={pageLang} />

          <div className="customer_partners">
            <HomeCustomers />

            <div className="home-partners partners" id='partners'>
              <div className="p-title">
                <FormattedMessage id="index.Partners" />
                <div className='p-search'>
                  <Search
                    placeholder={intl[pageLang].index.searchContent}
                    allowClear
                    enterButton={<div className="search-btn"><Icon type="icon-search" size={16} /><FormattedMessage id="index.search" /></div>}
                    onSearch={onSearch}
                    size='large'
                    className='search-input-box'
                  />
                  <Link to={`${pagePrefix}/partners/`} className="btn download"><FormattedMessage id="index.readMore" /></Link>
                  <Link to={`${pagePrefix}/apply/`} className="btn download"><FormattedMessage id="index.apply" /></Link>
                </div>
                <div className='p-search-mobile'>
                  <Search
                    placeholder={intl[pageLang].index.searchContent}
                    allowClear
                    enterButton={<div className="search-btn"><Icon type="icon-search" size={10} /><FormattedMessage id="index.search" /></div>}
                    onSearch={onSearch}
                    className='search-input-box'
                  />
                  <Dropdown overlay={menu} placement="bottomRight" arrow trigger={['click']}>
                    <div className='download'><Icon type="icon-more1" size={16} /></div>
                  </Dropdown>
                </div>
              </div>

              <HomePartners pageLang={pageLang} pageType="partners-home" />

            </div>
          </div>

          <div className="contact">
            <div className="title"><FormattedMessage id="aboutUs.nextStep" /></div>
            <a target='_blank' href={`${pagePrefix}/contact/`} className="jump-contact">
              <span className="label"><FormattedMessage id="aboutUs.contactUs" /></span>
              <span className="icon">&gt;</span>
            </a>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default Index