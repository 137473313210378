import React, { useState, useEffect, useRef } from 'react';
import { FormattedMessage } from "react-intl"

import '../../style/components/homeCustomers.scoped.scss'

const HomeCustomers = ({ pageContext }) => {
  const Total = 19 // 总数
  const Lines = 4  // 行数
  let timer = []
  let boxTopH = 0 // 盒子，距离顶部的距离
  let boxH = 0 // 盒子高度
  let windowH = 0 // 窗口高度
  let windowW = 0

  useEffect(() => {
    const boxNode = document.querySelector('.customers-line-box')
    boxTopH = getElementToPageTop(boxNode)
    boxH = boxNode.clientHeight
    windowH = document.body.clientHeight
    windowW = document.body.clientWidth

    customersMoving()
    window.addEventListener("scroll", scrollListenerFunc)
    window.addEventListener("resize", resizeListenerFunc)

    return () => {
      window.removeEventListener("scroll", scrollListenerFunc)
      window.removeEventListener("resize", resizeListenerFunc)
      clearMovingInterval()
    }
  }, [])

  // 客户
  function getCustomers () {
    const LineTotal = parseInt(Total/Lines) // 一行展示的个数

    let _remainder = Total % Lines // 余数
    let _start = 0

    const CustomersArr = Array(Lines).fill(1).map((v, i) =>{
      let end = _start + LineTotal + (_remainder > 0 ? 1 : 0)
      const itemArr = Array(Total).fill(1).map((v, i) => i + 1).slice(_start, end)
      _remainder = !_remainder ? 0 : _remainder - 1
      _start = end
      return itemArr
    })
    return CustomersArr.map((item, itemIdx) => {
      return <div key={itemIdx} className="line-box">
        <div className={`custome-line line_${itemIdx}`}>
          {item.map(i => <div key={i} className={`c-icon c-icon-${i}`}></div>)}
          {item.map(i => <div key={i + '_1'} className={`c-icon c-icon-${i}`}></div>)}
          {item.map(i => <div key={i + '_1'} className={`c-icon c-icon-${i}`}></div>)}
        </div>
      </div>
    })
  }

  // 监控 函数
  function scrollListenerFunc () {
    // 当滚动进入可视区时，增加动画
    const scrollTopVal = document.documentElement.scrollTop || document.body.scrollTop
    const boxNode = document.querySelector('.home-customers')
    if (windowW <= 768) return
    if (scrollTopVal >= boxTopH - windowH) {
      if (!boxNode.classList.contains('animate__fadeInUp')) boxNode.classList.add('animate__fadeInUp')
    } else {
      boxNode.classList.remove('animate__fadeInUp')
    }
  }
  function resizeListenerFunc () {
    windowH = document.body.clientHeight
    windowW = document.body.clientWidth
    clearMovingInterval()
    customersMoving()
  }

  // 动画
  function customersMoving () {
    const animationNodes = document.querySelectorAll('.custome-line')
    if (!animationNodes || animationNodes.length === 0) return

    animationNodes.forEach((node, i) => {
      const scroll = node.scrollWidth / 3
      let len = 0
      timer[i] = setInterval(() => {
        node.style.transform = `translateX(${i%2 === 0 ? -len : len}px)`
        len++
        if (len >= scroll) {
          len = 0
        }
      }, 80)
    })
  }
  function clearMovingInterval () {
    timer.forEach(i => {
      clearInterval(i)
    })
    timer = []
  }

  // 获取元素到顶部的距离
  function getElementToPageTop (el) {
    if (!el) return
    if (el.parentElement) {
      return getElementToPageTop(el.parentElement) + el.offsetTop
    }
    return el.offsetTop
  }

  return (
    <div className="home-customers animate__animated">
      <div className="customers-bg"></div>
      <div className="c-title-box"><div className="c-title"><FormattedMessage id="index.Customers" /></div></div>
      <div className="customers-line-box">{getCustomers()}</div>
    </div>
  )
}
export default HomeCustomers